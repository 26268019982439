import {
  CreditCardOutlined,
  DownOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Tabs } from "antd";

import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import VisaBtn from "../../assets/images/visa.png";
import BankAcc from "../../assets/images/bank-account.png";
import BankHome from "../../assets/images/bank-home.png";
import Paypal from "../../assets/images/paypal.png";
import TabPane from "antd/es/tabs/TabPane";
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAppContext } from "../../context/AppContext";
import { BackArrow } from "./Index";
import lang from "../../helper/langHelper";

function Deposite() {
  const { request } = useRequest();
  const { userProfile, setRefreshBalance } = useContext(AuthContext);
  const { isMobile } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [withdraw, showWithdraw] = useState(false);
  const [desposit, showDeposit] = useState(false);
  const [list, setList] = useState([]);
  const [amount, setAmount] = useState("");

  const [activeTab, setActiveTab] = useState("creditcard");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const getTransaction = (id) => {
    setLoading(true);
    request({
      url: `/common/wallet?userId=${id}`,
      method: "GET",
      onSuccess: (res) => {
        setLoading(false);
        const { data } = res;
        if (data.data.length) {
          setList(data.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!userProfile) return;
  }, [userProfile, refresh]);

  const [isVisaDropdownOpen, setIsVisaDropdownOpen] = useState(false);

  const handleVisaTypeClick = () => {
    setIsVisaDropdownOpen(!isVisaDropdownOpen);
  };

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = () => {
    let payload = {};
    payload.balance = amount;
    request({
      url: "app/payment/add-balance",
      method: "PUT",
      data: payload,
      onSuccess: ({ data, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          setAmount();
          setRefreshBalance((prev) => !prev);
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <section className="main">
        <Header />

        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}

            <Col span={24} md={16} lg={18} xl={15} className="mx-auto mt-5">
              <div className="">
                <Row gutter={16}>
                  <Col span={24} lg={12}>
                    <Tabs
                      className="deposit-tab"
                      activeKey={activeTab}
                      onTabClick={handleTabChange}
                      tabPosition="left"
                    >
                      <TabPane
                        tab={
                          <div className="deposit-tab-button">
                            <div className="credit-icon">
                              <CreditCardOutlined />
                            </div>
                            <div className="tab-outer-text">
                              <h4>{lang("Credit Card")}</h4>
                              <p>
                                {lang(
                                  "Add any Visa or MasterCard debit or credit card to your OneGold Account."
                                )}
                              </p>
                            </div>
                          </div>
                        }
                        key="creditcard"
                      ></TabPane>

                      <TabPane
                        tab={
                          <div className="deposit-tab-button">
                            <div className="credit-icon">
                              <img src={Paypal} />
                            </div>
                            <div className="tab-outer-text">
                              <h4>PayPal</h4>
                              <p>
                                {lang(
                                  "Securely log in to your PayPal account to buy instantly."
                                )}
                              </p>
                            </div>
                          </div>
                        }
                        key="paypal"
                      ></TabPane>

                      <TabPane
                        tab={
                          <div className="deposit-tab-button">
                            <div className="credit-icon">
                              <img src={BankHome} />
                            </div>
                            <div className="tab-outer-text">
                              <h4>Bank Wire</h4>
                              <p>
                                {lang(
                                  "Wire funds directly into your account. Recommended when funding large amounts."
                                )}
                              </p>
                            </div>
                          </div>
                        }
                        key="bankwire"
                      ></TabPane>

                      <TabPane
                        tab={
                          <div className="deposit-tab-button">
                            <div className="credit-icon">
                              <img src={BankAcc} />
                            </div>
                            <div className="tab-outer-text">
                              <h4>{lang("Bank Account")}</h4>
                              <p>
                                {lang(
                                  "Link your bank account to instantly fund your OneGold account and start buying today."
                                )}
                              </p>
                            </div>
                          </div>
                        }
                        key="bankaccount"
                      ></TabPane>
                    </Tabs>
                  </Col>
                  <Col span={24} lg={12}>
                    {activeTab === "creditcard" && (
                      <div className="tab-body-credit">
                        <div className="creditcard-cash-balance">
                          <h3 className=" ">{lang("Your Cash Balance")}</h3>
                          <h3>
                            {"$ "}
                            {userProfile?.cashBalance}
                          </h3>
                        </div>
                        <Form layout="vertical" className="row-col mt-3">
                          <Form.Item
                            className="username-form"
                            label="Deposit Amount"
                            name="amount"
                          >
                            <div className="currency-selec-group">
                              <Input
                                placeholder="0.00"
                                className="ant-input ant-input-status-success  buy-sale"
                                type="text"
                              />
                              <div className="currny-names">
                                <span>$</span>
                              </div>
                            </div>
                          </Form.Item>
                          <Form.Item
                            className="username-form"
                            label="Account"
                            name="amount"
                          >
                            <div className="position-relative">
                              <div
                                className="currency-group"
                                onClick={handleVisaTypeClick}
                              >
                                <div className="money-detail">
                                  <img src={VisaBtn} />
                                </div>
                                <div className="currncy-ds">
                                  <div>
                                    <h6>$</h6>
                                    <p>Ending in 8066</p>
                                  </div>
                                  <div className="dawn-angle-er">
                                    <DownOutlined />
                                  </div>
                                </div>
                              </div>
                              {isVisaDropdownOpen && (
                                <div className="visa-dropdawn ">
                                  <div className="currency-group">
                                    <div className="money-detail">
                                      <img src={VisaBtn} />
                                    </div>
                                    <div className="currncy-ds">
                                      <div>
                                        <h6>$</h6>
                                        <p>Ending in 8066</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="add-btn-currency">
                                    <Link>
                                      <PlusCircleOutlined />
                                      Add new account
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Form.Item>
                        </Form>
                        <div className="money-footer">
                          <Button className="btn">Continue</Button>
                          <p className="withdrawing-error">
                            Please note, if funding an account and subsequently
                            withdrawing funds within 60 days, funds originating
                            from bank ACHs, credit cards and PayPal will be sent
                            to your originating bank account, credit card or
                            PayPal account, regardless of the method you select
                            when withdrawing your funds.
                          </p>
                        </div>
                      </div>
                    )}
                    {activeTab === "paypal" && (
                      <div>
                        <div className="tab-body-credit">
                          <div className="creditcard-cash-balance">
                            <h3 className=" ">Your Cash Balance</h3>
                            <h3>
                              {"$ "}
                              {userProfile?.cashBalance}
                            </h3>
                          </div>
                          <Form layout="vertical" className="row-col mt-3">
                            <Form.Item
                              className="username-form"
                              label="Deposit Amount"
                              name="amount"
                            >
                              <div className="currency-selec-group">
                                <Input
                                  placeholder="0.00"
                                  className="ant-input ant-input-status-success  buy-sale"
                                  type="text"
                                />
                                <div className="currny-names">
                                  <span>$</span>
                                </div>
                              </div>
                            </Form.Item>
                            <Form.Item
                              className="username-form"
                              label="Account"
                              name="amount"
                            >
                              <div className="position-relative">
                                <div
                                  className="currency-group"
                                  onClick={handleVisaTypeClick}
                                >
                                  <div className="money-detail">
                                    <img src={VisaBtn} />
                                  </div>
                                  <div className="currncy-ds">
                                    <div>
                                      <h6>$</h6>
                                      <p>Ending in 8066</p>
                                    </div>
                                    <div className="dawn-angle-er">
                                      <DownOutlined />
                                    </div>
                                  </div>
                                </div>
                                {isVisaDropdownOpen && (
                                  <div className="visa-dropdawn ">
                                    <div className="currency-group">
                                      <div className="money-detail">
                                        <img src={VisaBtn} />
                                      </div>
                                      <div className="currncy-ds">
                                        <div>
                                          <h6>$</h6>
                                          <p>Ending in 8066</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="add-btn-currency">
                                      <Link>
                                        <PlusCircleOutlined />
                                        Add new account
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Form.Item>
                          </Form>
                          <div className="money-footer">
                            <Button className="btn">Continue</Button>
                            <p className="withdrawing-error">
                              Please note, if funding an account and
                              subsequently withdrawing funds within 60 days,
                              funds originating from bank ACHs, credit cards and
                              PayPal will be sent to your originating bank
                              account, credit card or PayPal account, regardless
                              of the method you select when withdrawing your
                              funds.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === "bankwire" && (
                      <div>
                        <div className="tab-body-credit">
                          <div className="creditcard-cash-balance">
                            <h3 className=" ">Your Cash Balance</h3>
                            <h3>
                              {"$ "}
                              {userProfile?.cashBalance}
                            </h3>
                          </div>
                          <Form layout="vertical" className="row-col mt-3">
                            <Form.Item
                              className="username-form"
                              label="Deposit Amount"
                              name="amount"
                            >
                              <div className="currency-selec-group">
                                <Input
                                  placeholder="0.00"
                                  className="ant-input ant-input-status-success  buy-sale"
                                  type="text"
                                />
                                <div className="currny-names">
                                  <span>$</span>
                                </div>
                              </div>
                            </Form.Item>
                            <Form.Item
                              className="username-form"
                              label="Account"
                              name="amount"
                            >
                              <div className="position-relative">
                                <div
                                  className="currency-group"
                                  onClick={handleVisaTypeClick}
                                >
                                  <div className="money-detail">
                                    <img src={VisaBtn} />
                                  </div>
                                  <div className="currncy-ds">
                                    <div>
                                      <h6>$</h6>
                                      <p>Ending in 8066</p>
                                    </div>
                                    <div className="dawn-angle-er">
                                      <DownOutlined />
                                    </div>
                                  </div>
                                </div>
                                {isVisaDropdownOpen && (
                                  <div className="visa-dropdawn ">
                                    <div className="currency-group">
                                      <div className="money-detail">
                                        <img src={VisaBtn} />
                                      </div>
                                      <div className="currncy-ds">
                                        <div>
                                          <h6>USD</h6>
                                          <p>Ending in 8066</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="add-btn-currency">
                                      <Link>
                                        <PlusCircleOutlined />
                                        Add new account
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Form.Item>
                          </Form>
                          <div className="money-footer">
                            <Button className="btn">Continue</Button>
                            <p className="withdrawing-error">
                              Please note, if funding an account and
                              subsequently withdrawing funds within 60 days,
                              funds originating from bank ACHs, credit cards and
                              PayPal will be sent to your originating bank
                              account, credit card or PayPal account, regardless
                              of the method you select when withdrawing your
                              funds.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === "bankaccount" && (
                      <div>
                        <div className="tab-body-credit">
                          <div className="creditcard-cash-balance">
                            <h3>Your Cash Balance</h3>
                            <h3>
                              {"$ "}
                              {userProfile?.cashBalance}
                            </h3>
                          </div>
                          <Form layout="vertical" className="row-col mt-3">
                            <Form.Item
                              className="username-form"
                              label="Deposit Amount"
                              name="amount"
                            >
                              <div className="currency-selec-group">
                                <Input
                                  placeholder="0.00"
                                  className="ant-input ant-input-status-success buy-sale"
                                  type="text"
                                  value={amount}
                                  onChange={handleInputChange}
                                />
                                <div className="currny-names">
                                  <span>$</span>
                                </div>
                              </div>
                            </Form.Item>
                          </Form>
                          <div className="money-footer">
                            <Button className="btn" onClick={handleSubmit}>
                              Continue
                            </Button>
                            <p className="withdrawing-error">
                              Please note, if funding an account and
                              subsequently withdrawing funds within 60 days,
                              funds originating from bank ACHs, credit cards and
                              PayPal will be sent to your originating bank
                              account, credit card or PayPal account, regardless
                              of the method you select when withdrawing your
                              funds.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        {withdraw && (
          <Withdraw
            show={withdraw}
            hide={() => showWithdraw(false)}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}

        {desposit && (
          <Desposit
            show={desposit}
            hide={() => showDeposit(false)}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
      </section>
      <Footer />
    </>
  );
}

const Withdraw = ({ show, hide, refresh }) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { cashBalance } = values;

    setLoading(true);

    request({
      url: "/app/auth/withdrawOrAdd ",
      method: "PUT",
      data: { balanceStatus: "withdraw", cashBalance },
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          form.setFieldsValue({ cashBalance: null });
          if (refresh) refresh();
          hide();
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      className="modal01"
      onCancel={hide}
      title="Withdraw Amount"
      open={show}
      footer={false}
    >
      <div>
        <Form
          layout="vertical"
          className="row-col"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            className="username-form"
            label="Amount"
            name="cashBalance"
            rules={[{ required: true, message: "Please enter the amount!" }]}
          >
            <Input className="form-of-type" type="text" />
          </Form.Item>

          <div className="mt-3 mx-auto text-center">
            <Form.Item className="username-form">
              <Button loading={loading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const Desposit = ({ show, hide, refresh }) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { cashBalance } = values;

    setLoading(true);

    request({
      url: "/app/auth/withdrawOrAdd ",
      method: "PUT",
      data: { balanceStatus: "add", cashBalance },
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          form.setFieldsValue({ cashBalance: null });
          if (refresh) refresh();
          ShowToast(data.message, Severty.SUCCESS);
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      className="modal01"
      onCancel={hide}
      title="Add Amount"
      open={show}
      footer={false}
    >
      <div>
        <Form
          layout="vertical"
          className="row-col"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            className="username-form"
            label="Amount"
            name="cashBalance"
            rules={[{ required: true, message: "Please enter the amount!" }]}
          >
            <Input className="form-of-type" type="text" />
          </Form.Item>

          <div className="mt-3 mx-auto text-center">
            <Form.Item className="username-form">
              <Button loading={loading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default Deposite;
