import { Badge, Button, Card, Col, Row, Skeleton, Slider, Switch } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAuthContext } from "../../context/AuthContext";
import PrivacyImg from "../../assets/images/privacy-img.png";

function FeesIndex() {
  const { request } = useRequest();

  const [loading, setLoading] = useState(false);

  const [vault, vaultList] = useState([]);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const [goldHolding, setGoldHolding] = useState(0);
  const [silverHolding, setSilverHolding] = useState(0);
  const [holding, setHolding] = useState(0);
  const [quarterly, setQuarterly] = useState(0);
  const [annualy, setAnnualy] = useState(0);
  const [minimum, setMinimum] = useState(0);
  const [isAnnual, setIsAnnual] = useState(false);

  const { isLoggedIn } = useAuthContext();

  const getVault = () => {
    setLoading(true);
    request({
      url: `/app/vault/getVaultList`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        if (!data.data.length) return;
        vaultList(
          data.data.sort((a, b) =>
            a.categoryId.categories.localeCompare(b.categoryId.categories)
          )
        );
        setMinimum(data.data[0]?.minimumStorageFee);
      },
      onError: () => setLoading(false),
    });
  };

  useEffect(() => {
    getVault();
  }, []);

  useEffect(() => {
    if (!(goldHolding || silverHolding)) return;

    setHolding(goldHolding + silverHolding);

    const goldFee = vault[0].storageFee;
    const silverFee = vault[1].storageFee;

    const goldCharge = goldHolding * (goldFee / 100);

    const silverCharge = silverHolding * (silverFee / 100);

    setAnnualy((goldCharge + silverCharge).toFixed(2));
    setQuarterly(((goldCharge + silverCharge) / 4).toFixed(2));
  }, [goldHolding, silverHolding]);

  const onChange = (checked) => {
    setIsAnnual(checked);
  };

  return (
    <>
      <section className="main">
        <Header />
        <div className="fess-section">
          <Row className="justify-content-center">
            <Col span={24} md={12} lg={8} className="text-center">
              <div className="price-outers-main">
                <h1>Low Storage Fees</h1>
                <p>
                  Own metal in vaults around the globe by leveraging our
                  relationships with industry leaders. Enjoy the peace of mind
                  knowing your holdings are secured, insured, and audited. Mynt
                  provides all of this at our lowest possible cost. See for
                  yourself
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="position-relative">
          <div className="curve-shape">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <div className="fees-storage-add">
          <Container>
            <Row>
              <Col span={24}>
                <Card className="card-fees">
                  <Row>
                    {loading
                      ? [1, 2, 3, 4, 5].map((item) => (
                          <Skeleton active key={item} />
                        ))
                      : vault.map((item, index) => (
                          <Col
                            span={24}
                            md={12}
                            className="fess-border"
                            key={index}
                          >
                            <div className="fees-rate">
                              <div className="headding-pri">
                                <Badge className="bg-secondary" title="">
                                  {item.categoryId.categories} Storage Rate
                                </Badge>
                              </div>
                              <div className="price-body-t fees-rate-menu">
                                <h6>{item.storageFee}%</h6>
                                <p>per annum</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                    {/* <Col span={24} md={12} className="border-right">
                                            <div className="fees-rate">
                                                <div className="headding-pri">
                                                    <Badge className="bg-secondary" title="" >Gold Storage Rate</Badge>
                                                </div>
                                                <div className="price-body-t fees-rate-menu">
                                                    <h6>0.12%</h6>
                                                    <p>per annum</p>
                                                </div>
                                            </div>
                                        </Col> */}
                    {/* <Col span={24} md={8}>
                                            <div className="fees-rate">
                                                <div className="headding-pri">
                                                    <Badge className="bg-secondary" title="" >Gold Storage Rate</Badge>
                                                </div>
                                                <div className="price-body-t fees-rate-menu">
                                                    <h6>0.12%</h6>
                                                    <p>per annum</p>
                                                </div>
                                            </div>
                                        </Col> */}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mb-5">
          <Container>
            <Row>
              <Col span={24}>
                <Card className="clc-fees-card">
                  <div className="clc-list--type">
                    <div className="headding-clc">
                      <Badge className="bg-secondary" title="">
                        CALCULATE
                      </Badge>
                      <p>Calculate your estimated storage fees</p>
                    </div>
                    <div className="right-clc-type">
                      <Badge className="bg-light" title="">
                        Minimum Annual Fee: ${minimum} , Paid Quarterly: $
                        {minimum / 4}
                      </Badge>
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col span={24} md={12}>
                      <div className="calculate-list ">
                        <div className="list-clc-headding">
                          <Badge className="bg-secondary" title="">
                            YOUR HOLDINGS
                          </Badge>
                          <p>YOUR HOLDINGS</p>
                        </div>
                        <div className="cls-currncy">
                          <span>$</span>
                          <p className="number-text">{holding}</p>
                        </div>
                        <div className="list-group-clc">
                          <Badge className="bg-gold" title="">
                            GOLD
                          </Badge>
                          <h6>
                            {goldHolding.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </h6>
                          <div className="range-line">
                            <Slider
                              min={0}
                              max={1000000}
                              step={5000}
                              onChange={(newValue) => {
                                setGoldHolding(newValue);
                              }}
                              value={
                                typeof goldHolding === "number"
                                  ? goldHolding
                                  : 0
                              }
                              defaultValue={30}
                              //  disabled={disabled}
                            />
                          </div>
                        </div>
                        <div className="list-group-clc">
                          <Badge className="bg-silver" title="">
                            SILVER
                          </Badge>
                          <h6>
                            {silverHolding.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </h6>
                          <div className="range-line">
                            <Slider
                              defaultValue={30}
                              // disabled={disabled}
                              onChange={(newValue) => {
                                setSilverHolding(newValue);
                              }}
                              min={0}
                              max={1000000}
                              step={2000}
                              value={
                                typeof silverHolding === "number"
                                  ? silverHolding
                                  : 0
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="list-group-clc">
                                                    <Badge className="bg-platinum" title="" >PLATINUM</Badge>
                                                    <h6>$ 0.00</h6>
                                                    <div className="range-line">
                                                        <Slider defaultValue={30} disabled={disabled} />
                                                    </div>
                                                </div> */}
                      </div>
                    </Col>
                    <Col span={24} md={12}>
                      <div className="billed-clc">
                        <div className="list-clc-headding">
                          <Badge className="bg-secondary" title="">
                            YOUR HOLDINGS
                          </Badge>
                          <div className="pricing-switch">
                            <span className="">Quarterly</span>
                            <Switch
                              checked={isAnnual}
                              defaultChecked
                              onChange={onChange}
                            />
                            <span className="">Annual</span>
                          </div>
                        </div>
                        <div className="clc-footer-right">
                          <div className="cls-currncy">
                            <span>$</span>
                            <p className="number-text">
                              {isAnnual ? annualy : quarterly}
                            </p>
                          </div>
                          <p className="detail-clc-p">
                            Storage fees are billed quarterly based on the
                            average daily balance of your holdings multiplied by
                            the storage rate of the metal.
                          </p>
                        </div>
                      </div>
                      <Link
                        to={isLoggedIn ? "/" : "/login"}
                        className="btn-clc-main"
                      >
                        Get started
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="storage-section">
          <Container>
            <Row>
              <Col span={24} md={12}>
                <div className="st-left">
                  <Badge className="bg-secondary" title="">
                    STORAGE PARTNERS
                  </Badge>
                  <h2>Our Storage Partners</h2>
                  <p>
                    Whether you are storing in Canada (at the Royal Canadian
                    Mint) or in the U.S. (at APMEX, or Loomis International) or
                    in Switzerland (at Loomis International facility), you can
                    rest assured knowing that your holdings are secured by
                    industry leaders. All of these positions are insured and
                    undergo regular audits. This peace of mind often comes at a
                    high cost. In this case, OneGold has worked to ensure that
                    our customers can leverage secure storage without breaking
                    the bank
                  </p>
                </div>
              </Col>
              <Col span={24} md={12}>
                <div className="st-img-right">
                  <img src={PrivacyImg} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default FeesIndex;
