import { Card, Col, Row, Skeleton } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { CardBody, Container } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import PageBack from "../../assets/images/back.svg";
import {
  default as Begin01,
  default as Begin02,
  default as Begin03,
} from "../../assets/images/begin02.jpg";
import Header from "../../components/layout/Header";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import Footer from "../../components/layout/Footer";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";

function ResourcesIndex() {
  const { request } = useRequest();
  const [resource, setResource] = useState();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { language } = useAppContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const getResource = () => {
    setLoading(true);
    request({
      url: `/common/getResources/${id}`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        //setData(data.data);
        setResource(data);
        setLoading(false);
      },
    });
  };

  const getResources = () => {
    // setLoading(true)
    request({
      url: `/common/getResources`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setData(data.data.filter((item) => item._id != id).splice(0, 2));
      },
    });
  };

  useEffect(() => {
    getResource();
    getResources();
  }, [id]);

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <div className="page-back">
                  <div
                    style={{ cursor: "pointer" }}
                    className="main-new-back-buttonhhg5"
                    onClick={() => navigate(-1)}
                  >
                    <img src={PageBack} />
                    {lang("Back")}
                  </div>
                </div>
              </Col>
              <Col sm={24} md={16}>
                <Row gutter={12}>
                  {loading ? (
                    [1, 2, 3].map((item) => <Skeleton active key={item} />)
                  ) : (
                    <Col span={24} md={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-body ">
                            <img
                              className="resoureces-img resources-cmt"
                              src={resource?.image}
                              onError={(e) =>
                                (e.target.src =
                                  "https://b2btobacco.s3.amazonaws.com/mint/file_1712037538213.jpg")
                              }
                            ></img>
                            <h5>
                              {language == "en"
                                ? resource?.title
                                : resource?.es_title}
                            </h5>

                            <div
                              className="resoureces-http"
                              dangerouslySetInnerHTML={{
                                __html: `${
                                  language == "en"
                                    ? resource?.description
                                    : resource?.es_description
                                }`,
                              }}
                            ></div>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col sm={24} md={8}>
                <div className="invest-card">
                  <Row>
                    {data.map((item) => (
                      <Col span={24} md={24} className="mb-3" key={item._id}>
                        <div className="resources-card-p">
                          <Card>
                            <CardBody
                              className="resources-card-body cmt-resource"
                              onClick={() => navigate(`/${item._id}/resource`)}
                            >
                              <img
                                height={220}
                                src={item.image}
                                onError={(e) => (e.target.src = Begin01)}
                              ></img>
                              <h5>
                                {language == "en" ? item.title : item.es_title}
                              </h5>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default ResourcesIndex;
