import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Switch,
  
  } from "antd";
  import 'react-phone-input-2/lib/style.css'
  import React, { useState, useContext, useEffect } from "react";
  import 'owl.carousel/dist/assets/owl.carousel.css';
  import 'owl.carousel/dist/assets/owl.theme.default.css';
  import useRequest from "../../hooks/useRequest";
  import { Link, useNavigate } from "react-router-dom";
  import useDebounce from "../../hooks/useDebounce";
  import 'owl.carousel/dist/assets/owl.carousel.css';
  import 'owl.carousel/dist/assets/owl.theme.default.css';
  import { CardBody, CardFooter, CardTitle, Container, Nav, Navbar, Accordion } from "react-bootstrap";
  
  import Header from "../../components/layout/Header"
  import Footer from "../../components/layout/Footer"
  import PrivacyImg from '../../assets/images/privacy-img.png';
  function PricingIndex() {
    const { request } = useRequest()
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate()
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
      };
    return (
      <>
        <section className="main">
          <Header />
          <Container>
  
            <div className="home-outers">
             
                <div className="pricing-page">
                    <div className="pricing-switch">
                        <span className="" >Monthly</span>
                        <Switch defaultChecked onChange={onChange} />
                        <span className="" >Yearly</span>
                    </div>
                    <div className="pricing-plans">
                        <div className="pricing-plan">
                            <h2>Basic</h2>
                            <p className="price"> <span>120</span> /month</p>
                            <div className="pricing-features">
                                <h4>Basic Feature</h4>
                            <ul>
                                <li>Single Domain</li>
                                <li>50 GB SSD</li>
                                <li>1 TB Premium Bandwidth</li>
                            </ul>
                            </div>
                            <button className="pricing-btn">Subscribe</button>
                        </div>
                        <div className="pricing-plan">
                            <div class="most-active">
                                Most Popular
                            </div>
                            <h2>Pro</h2>
                            <p className="price"><span>62</span>/month</p>
                            <div className="pricing-features">
                                <h4>Pro Feature</h4>
                            <ul>
                                <li>Single Domain</li>
                                <li>Feature 2</li>
                                <li>1 TB Premium Bandwidth</li>
                            </ul>
                            </div>
                            <button className="pricing-btn">Subscribe</button>
                        </div>
                        <div className="pricing-plan">
                            <h2>Premium</h2>
                            <p className="price"><span>50</span>/month</p>
                            <div className="pricing-features">
                                <h4> Premium Feature</h4>
                            <ul>
                                <li>Single Domain</li>
                                <li>Feature 2</li>
                                <li>1 TB Premium Bandwidth</li>
                            </ul>
                            </div>
                            <button className="pricing-btn">Subscribe</button>
                        </div>
                    </div>
                </div>
            
            </div>
          </Container>
  
        </section>
        <Footer />
      </>
    );
  }
  
  
  
  export default PricingIndex;
  