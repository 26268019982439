import { Col, Row } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";

import TermsImg from "../../assets/images/terms-img.png";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/api_urls";
import { Severty, ShowToast } from "../../helper/toast";

const EmailVerify = () => {
  const { request } = useRequest();
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const token = urlParams.get("token");

    request({
      url: `${apiPath.emailVerify}?email=${email}&token=${token}`,
      method: "POST",
      data: { email, token },
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        ShowToast("Email Verify successfully", Severty.SUCCESS);
        navigate("/login");
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  }, []);

  return (
    <>
      <section className="main">
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <div className="email-verfy">
                <Spinner />
              </div>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EmailVerify;
