import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Tooltip,
  Select,
  Modal,
  List,
} from "antd";
import "react-phone-input-2/lib/style.css";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router-dom";
import { usePlaidLink } from "react-plaid-link";
import { Footer, Header } from "antd/lib/layout/layout";

import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";

// function PlaidLink({ onSelect }) {
//   const { request } = useRequest();
//   const { userProfile } = useContext(AuthContext);
//   const { isMobile } = useAppContext();
//   const [loading, setLoading] = useState(false);
//   const [refresh, setRefresh] = useState(false);
//   const [accountList, setAccountList] = useState([]);
//   const navigate = useNavigate();

//   const [token, setToken] = useState(null);

//   const getTransaction = (id) => {
//     setLoading(true);
//     request({
//       url: `/app/payment/create_link_token`,
//       method: "POST",
//       onSuccess: (res) => {
//         setLoading(false);
//         const { data } = res;
//         console.log(data, "data");
//         setToken(data.link_token);
//       },
//       onError: (error) => {
//         ShowToast(error.response.data.message, Severty.ERROR);
//         setLoading(false);
//       },
//     });
//   };
//   const getAccount = () => {
//     // console.log(userProfile.accessToken,"accessToken----------------->")
//     request({
//       url: `/app/payment/get-accounts`,
//       method: "POST",
//       onSuccess: (res) => {
//         const { data } = res;
//         console.log(data, "RESouce------------>");
//         setLoading(false);
//         setAccountList(data);
//       },
//       onError: (err) => {
//         setLoading(false);
//         if (err?.response?.data) {
//           ShowToast(err?.response?.data?.message, Severty.ERROR);
//         }
//       },
//     });
//   };

//   useLayoutEffect(() => {
//     getTransaction();
//     // getAccount();
//   }, []);

//   const onSuccess = useCallback((publicToken, metadata) => {
//     // send public_token to your server
//     // https://plaid.com/docs/api/tokens/#token-exchange-flow
//     console.log(publicToken, metadata);
//     setLoading(true);
//     request({
//       url: `/app/payment/public_token_exchange`,
//       method: "POST",
//       data: { publicToken, metadata },
//       onSuccess: (res) => {
//         setLoading(false);
//         const { data } = res;
//         console.log(data, "data");
//         // setToken(data.link_token)
//         if (onSelect) onSelect(data.accounts);
//       },
//       onError: (error) => {
//         ShowToast(error.response.data.message, Severty.ERROR);
//         setLoading(false);
//       },
//     });
//   }, []);

//   const onEvent = useCallback((eventName, metadata) => {
//     // log onEvent callbacks from Link
//     // https://plaid.com/docs/link/web/#onevent
//     console.log(eventName, metadata);
//   }, []);

//   const onExit = useCallback((error, metadata) => {
//     // log onExit callbacks from Link, handle errors
//     // https://plaid.com/docs/link/web/#onexit
//     console.log(error, metadata);
//   }, []);

//   const config = {
//     token,
//     onSuccess,
//     onEvent,
//     onExit,
//   };

//   const { open, ready } = usePlaidLink(config);

//   useEffect(() => {
//     if (!token) return;
//     open();
//   }, [open, token]);

//   return (
//     <>
//       <section className="main">
//         <button onClick={() => open()} disabled={!ready}>
//           Connect a bank account
//         </button>
//       </section>
//     </>
//   );
// }

// export default PlaidLink;

function PlaidLink({ onSelect, show, hide }) {
  const { request } = useRequest();
  const { userProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState();
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getTransaction = () => {
    handleCancel();
    setLoading(true);
    request({
      url: `/app/payment/create_link_token`,
      method: "POST",
      onSuccess: (res) => {
        setLoading(false);
        const { data } = res;
        setToken(data.link_token);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  const getAccount = () => {
    setLoading(true);
    request({
      url: `/app/payment/get-accounts`,
      method: "GET",
      onSuccess: (res) => {
        setLoading(false);
        if (res) {
          const { metadata } = res;
          if (metadata && Array.isArray(metadata.accounts)) {
            setAccountList(metadata.accounts);
            showModal();
          } else {
            console.error(
              "Metadata.accounts is not an array or metadata is missing:",
              metadata
            );
          }
          showModal();
        } else {
          console.error("No data field in response:", res);
        }
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  useEffect(() => {
    const accessToken = userProfile?.accessToken;
    if (accessToken) {
      getAccount();
    } else {
      getTransaction();
    }
  }, [userProfile]);

  const onSuccess = useCallback((publicToken, metadata) => {
    setLoading(true);
    request({
      url: `/app/payment/public_token_exchange`,
      method: "POST",
      data: { publicToken, metadata },
      onSuccess: (res) => {
        setLoading(false);
        const { data } = res;
        if (onSelect) onSelect(data.accounts);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  }, []);

  const onEvent = useCallback((eventName, metadata) => {
    console.log(eventName, metadata);
  }, []);

  const onExit = useCallback((error, metadata) => {
    console.log(error, metadata);
  }, []);

  const config = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (!token) return;
    open();
  }, [open, token]);

  return (
    <>
      <section className="main">
        {loading && <p>Loading...</p>}
        {!loading && (
          <>
            <Modal
              className="modal01"
              title={"Bank Wire"}
              // open={show}
              onOk={handleOk}
              open={isModalOpen}
              // onCancel={hide}
              onCancel={handleCancel}
              footer={false}
            >
              <div className="bank-name-listing">
                <List
                  dataSource={accountList}
                  renderItem={(account, index) => (
                    <List.Item key={index}>
                      <div className="bank-name-d">
                        <h6>{account?.name}</h6>
                        <span>{account?.subtype}</span>
                      </div>
                      <div className="balance-dd">
                        <h6>
                          Available: <span>{account.balances.available}</span>
                        </h6>
                      </div>
                    </List.Item>
                  )}
                />
                <div className="bl-footer">
                  <Button
                    type="primary"
                    onClick={getTransaction}
                    disabled={ready}
                  >
                    Add Account
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        )}
      </section>
    </>
  );
}

export default PlaidLink;

{
  /* <Modal
              className="modal01"
              title={"Bank Wire"}
              open={show}
              onOk={handleOk}
              onCancel={hide}
              footer={false}
            >
              <div className="bank-name-listing">
                <ul>
                  {accountList?.map((account, index) => (
                    <li key={index}>
                      <div className="bank-name-d">
                        <h6> {account.name}</h6>
                        <span>checking</span>
                      </div>
                      <div className="balance-dd">
                        <h6>
                          Available: <span> {account.balances.available}</span>
                        </h6>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="bl-footer">
                  <Button onClick={() => open()} disabled={!ready}>
                    Add account
                  </Button>
                </div>
              </div>
            </Modal> */
}
