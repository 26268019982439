import {
    Button,
    Modal
} from "antd";
import React, { useState } from "react";
import { ModalBody } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';
import { useAuthContext } from "../context/AuthContext";
import useRequest from "../hooks/useRequest";
import { dateString } from "../helper/functions";


const TransactionModal = ({ show, hide, data }) => {

    if (!data) return null

    return (
        <>
            <Modal width={670} title={"Transaction Details"} className="modal01" open={show} onOk={hide} onCancel={hide} footer={false}>
                <ModalBody>
                    <div className="active-type-modal">
                        <ul>
                            {/* <li className="active-type-list">
                                <p>Description</p>
                                <h6>
                                {data.orderId.typeOfTransaction == 'Buy' && <> Exchange $ to {data.orderId.categoryId.categories} </>}
                            {data.orderId.typeOfTransaction == 'Sell' && <> Exchange {data.orderId.categoryId.categories}  to $ </>}
                            {data.orderId.typeOfTransaction == 'Swap' && <> Exchange {data.orderId.categoryId.categories} to  {data.orderId.swapId.categories} </>}
                                     

                                </h6>
                            </li> */}
                            <li className="active-type-list">
                                <p>Date</p>
                                <h6>{dateString(data.dateOfTransaction)}</h6>
                            </li>
                            <li className="active-type-list">
                                <p>Amount</p>
                                <h6>{data.orderId.quantity?.toFixed(3)} oz<span>({data.orderId.amount.toLocaleString("en-US", { style: "currency", currency: "USD" })})</span> </h6>
                            </li>
                            <li className="active-type-list">
                                <p>type</p>
                                <h6>Exchange</h6>
                            </li>
                            <li className="active-type-list">
                                <p>rate</p>
                                <h6>1oz</h6>
                            </li>
                            <li className="active-type-list">
                                <p>Fee</p>
                                <h6>{((data.processingFees ?? 0) + (data.networkFees ?? 0)).toLocaleString("en-US", { style: "currency", currency: "USD" })}</h6>
                            </li>
                            <li className="active-type-list">
                                <p>Status</p>
                                <h6>Completed</h6>
                            </li>
                        </ul>
                    </div>

                </ModalBody>

            </Modal>
        </>
    )
}



export default TransactionModal;
