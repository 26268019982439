import { Form, Row, Col, Button, Input } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import lang from "../../helper/langHelper";
import { Container } from "react-bootstrap";
import { Severty, ShowToast } from "../../helper/toast";

function ContactUsIndex() {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    request({
      url: "/app/auth/contact-us",
      method: "POST",
      data: values,
      onSuccess: ({ data, status,message }) => {
        if (status) {
          form.resetFields();
          ShowToast(message, Severty.SUCCESS);
        } else {
          ShowToast(message, "error");
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <div className="tital transactions-tital"></div>
              </Col>
              <Col span={24}>
                <div>
                  <Row
                    gutter={45}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Col span={24} md={12}>
                      <div className="about-mynt">
                        <Form
                          layout="vertical"
                          className="contact-detail"
                          form={form}
                          onFinish={handleSubmit} // Handle form submission
                        >
                          <div className="contact-headding">
                            <h4 className="mb-3">{lang("Contact Us")}</h4>
                          </div>
                          <Row gutter={12}>
                            {/* Name */}
                            <Col span={24} md={12}>
                              <Form.Item
                                className="contact-form"
                                name="name"
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Name is required!"),
                                  },
                                  {
                                    pattern: /^[a-zA-Z\s]*$/,
                                    message: lang(
                                      "Name must only contain letters!"
                                    ),
                                  },
                                ]}
                              >
                                <Input placeholder={lang("Name")} />
                              </Form.Item>
                            </Col>

                            {/* Email */}
                            <Col span={24} md={12}>
                              <Form.Item
                                className="contact-form"
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Email is required!"),
                                  },
                                  {
                                    type: "email",
                                    message: lang(
                                      "Please enter a valid email address!"
                                    ),
                                  },
                                ]}
                              >
                                <Input
                                  className="place-inne"
                                  placeholder={lang("Email")}
                                />
                              </Form.Item>
                            </Col>

                            {/* Message */}
                            <Col span={24} md={24}>
                              <Form.Item
                                className="contact-form"
                                name="message"
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Message is required!"),
                                  },
                                  {
                                    min: 10,
                                    message: lang(
                                      "Message must be at least 10 characters!"
                                    ),
                                  },
                                  {
                                    max: 500,
                                    message: lang(
                                      "Message must not exceed 500 characters!"
                                    ),
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  placeholder={lang("Your Message")}
                                  rows={4}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* Submit Button */}
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="ant-btn-default"
                            >
                              {lang("Submit")}
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default ContactUsIndex;
