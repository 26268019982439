import {
  Button,
  Col,
  Modal,
  Row
} from "antd";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from "react";
import { Container, ModalBody, ModalTitle, Tab, Tabs } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

import OrderCard from "../../components/order/OrderCard";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Gold from "./Gold";
import Silver from "./Silver";

function Market() {
  const { request } = useRequest()
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [customerGraphData, setGraphCustomerData] = useState({});
  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();

  const [productList, setProductList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [key, setKey] = useState('gold');


  const navigate = useNavigate()
  useEffect(() => {
    customerMonthlyCount({})
  }, [])



  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const customerMonthlyCount = (data) => {
    const Cmonths = data?.userMonthlyGraph?.map((item) => item?.month);
    const Ccounts = data?.userMonthlyGraph?.map((item) => item?.count);

    const customerChartData = {
      series: [
        {
          name: "User",
          data: Ccounts,
        },
      ],
      options: {
        chart: {
          height: 250,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: Cmonths,
        },
      },
    };
    setGraphCustomerData(customerChartData);
  };

  useEffect(() => {
    customerMonthlyCount({ userMonthlyGraph: [{ count: 14, month: "December 2023" }, { count: 20, month: "January 2024" }] })
  }, [])



  return (
    <>
    <div>Hello</div>
      <Footer />
    </>
  );
}



const RecurringBuy = ({ isModalOpen, handleOk, handleCancel }) => {

  return (
  <Modal width={670} className="modal02" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
    <ModalTitle className="payment-modal-tital ">Recurring Buys</ModalTitle>
    <ModalBody>
      <div className="invest-prize mt-3">
        <h1>$4.83 USD</h1>
      </div>
      <div className="invest-list">
        <ul class="invest-list-item">
          <li class="list-item-iner">
            <span>Payment Method</span>
            <p>Cash USD</p>
          </li>
          <li class="list-item-iner">
            <span>Recurring</span>
            <p>$2.00 every month (on the 12th)</p>
          </li>
          <li class="list-item-iner">
            <span>Next purchase</span>
            <p>Oct 12, 2024</p>
          </li>
          <li class="list-item-iner total-count">
            <span>Total</span>
            <span>$.56 USD</span>
          </li>
        </ul>
      </div>
    </ModalBody>
    <div className="modal-cancle-last mt-3 mx-auto">
      <Button>Add payment method</Button>
    </div>
  </Modal>
  )
}

export default Market;
