import moment from "moment";
import { Timezone } from "./timeZone";

export const getFileExtension = (url) => {
  // Get the last part of the URL after the last '/'
  const filename = url.substring(url.lastIndexOf("/") + 1);

  // Get the file extension by getting the last part of the filename after the last '.'
  const extension = filename.substring(filename.lastIndexOf(".") + 1);

  return extension;
};

export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      if (obj[key] === '{"min":0,"max":20000000}') {
      } else {
        return false;
      }
    }
  }
  return true;
};

export const dateString = (created_at, format = "LLL") => {
  if (!created_at) return;
  return moment(created_at).tz(Timezone).format(format);
};

export const getClientIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
};

export const getClientBrowserName = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";

  if (userAgent.includes("Chrome")) {
    browserName = "Google Chrome";
  } else if (userAgent.includes("Firefox")) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.includes("Safari")) {
    browserName = "Safari";
  } else if (userAgent.includes("Edge")) {
    browserName = "Microsoft Edge";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
    browserName = "Internet Explorer";
  }

  return browserName;
};

// Function to get the client's approximate location based on IP address
export const getClientLocation = async (ipAddress) => {
  try {
    const response = await fetch(`https://ipapi.co/${ipAddress}/json/`);
    const data = await response.json();
    return {
      city: data.city,
      region: data.region,
      country: data.country_name,
      latitude: data.latitude,
      longitude: data.longitude,
    };
  } catch (error) {
    console.error("Error fetching location:", error);
  }
};

export const capitalizeStr = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatNumber = (number) => {
  if (number < 1) {
    return number.toString();
  } else if (number < 100000) {
    return number.toString().replace(/0+$/, "").replace(/\.$/, "");
  }

  let suffix = "";
  let divisor = 1;

  if (number >= 1e12) {
    divisor = 1e12; // Trillion
    suffix = "T";
  } else if (number >= 1e9) {
    divisor = 1e9; // Billion
    suffix = "B";
  } else if (number >= 1e6) {
    divisor = 1e6; // Million
    suffix = "M";
  } else if (number >= 1e3) {
    divisor = 1e3; // Thousand
    suffix = "K";
  }

  // Format the number with the appropriate suffix
  let formattedNumber =
    (number / divisor).toFixed(1).replace(/\.0$/, "") + suffix;

  // Ensure it does not exceed maxLength
  if (formattedNumber.length > 20) {
    formattedNumber = formattedNumber.substring(0, 20);
  }
  return formattedNumber;
};
