import {
  Button,
  Col,
  Modal,
  Row
} from "antd";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from "react";
import { Container, ModalBody, ModalTitle, Tab, Tabs } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

import OrderCard from "../../components/order/OrderCard";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Gold from "./Gold";


function GoldPage() {
  const { request } = useRequest()
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [customerGraphData, setGraphCustomerData] = useState({});
  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();

  const [productList, setProductList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [key, setKey] = useState('gold');


  const navigate = useNavigate()
 
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24} lg={24}>

                <Gold />  

              </Col>
              <Col span={24} lg={8}>
                {/* <OrderCard /> */}
              </Col>
            </Row>
          </div>
        </Container>


      </section>
      <Footer />
    </>
  );
}


export default GoldPage;
