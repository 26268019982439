import {
  Row,
  Col,
  DatePicker,
  Button,
  Card,
  Form,
  Input,
  Select,
  Modal,
  Table,
  Tabs,
} from "antd";
import ArrowRightOutlined from "antd/es/icon";
import TabPane from "antd/es/tabs/TabPane";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import Main from "../../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CardTitle, Container, Accordion, Nav, Navbar } from "react-bootstrap";
import Logo from "../../assets/images/Logo_new_mynt white.svg";
import Usd from "../../assets/images/currency-usd.png";
import Exchange from "../../assets/images/exchange.png";
import User from "../../assets/images/user-bold.png";
import Nottfy from "../../assets/images/bell-outline.png";

import Dotted from "../../assets/images/dotted.svg";
import Warning from "../../assets/images/warning.svg";
import LimitAction from "../../assets/images/limit-action.svg";

function Indextransfer() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [customerGraphData, setGraphCustomerData] = useState({});
  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [tabValue, setTabValue] = useState();
  const [productList, setProductList] = useState([]);
  const [visibleView, setVisibleView] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    customerMonthlyCount({});
  }, []);

  const handleTabChange = (status) => {
    setTabValue(status);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const customerMonthlyCount = (data) => {
    const Cmonths = data?.userMonthlyGraph?.map((item) => item?.month);
    const Ccounts = data?.userMonthlyGraph?.map((item) => item?.count);

    const customerChartData = {
      series: [
        {
          name: "User",
          data: Ccounts,
        },
      ],
      options: {
        chart: {
          height: 250,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: Cmonths,
        },
      },
    };
    setGraphCustomerData(customerChartData);
  };

  return (
    <section className="main">
      <header>
        <Navbar expand="lg" className="p-0">
          <Navbar.Brand href="#">
            <img src={Logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto   nav-items-name">
              <Nav.Link href="#" className="nav-menu active">
                Home
              </Nav.Link>
              <Nav.Link href="#" className="nav-menu">
                Market
              </Nav.Link>
              <Nav.Link href="#" className="nav-menu">
                {" "}
                Portfolio{" "}
              </Nav.Link>
              <Nav.Link href="#" className="nav-menu">
                {" "}
                FAQ{" "}
              </Nav.Link>
            </Nav>
            <div className="btn-header">
              <Button className="ant-btn-header" type="button">
                <img src={Usd} /> USD{" "}
              </Button>
              <Button className="ant-btn-header" type="button">
                <img src={Exchange} /> Transfer{" "}
              </Button>
              <Button className="ant-btn-header" type="button">
                <img src={User} /> Account{" "}
              </Button>
              <Button className="ant-btn-header" type="button">
                <img src={Nottfy} />{" "}
              </Button>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </header>

      <Container>
        <div className="home-outers">
          <Row gutter={24}>
            <Col span={24}>
              <div className="table-headding">Transfer funds</div>
            </Col>
            <Col sm={24} md={24} className="mt-3">
              <Tabs className="transfer-tab" onTabClick={handleTabChange}>
                <TabPane tab="Deposit into mynt" key="deposit">
                  <div className="cash-deposit">
                    <h4>Add Cash</h4>
                    <Form>
                      <Form.Item
                        className="cash-deposit-form"
                        label="Amount"
                        name="amount"
                        rules={[
                          {
                            required: true,
                            message: "Please enter amount",
                          },
                        ]}
                      >
                        <Input className="form-of-type" type="text" />
                      </Form.Item>
                      <Button type="button" className="pricing-btn">
                        Continue
                      </Button>
                    </Form>
                  </div>
                </TabPane>
                <TabPane tab="Withdraw from mynt" key="dithdraw">
                  <Row gutter={16}>
                    <Col sm={24} md={16}>
                      <Card className="withdraw-card">
                        <CardTitle className="account-tital">
                          Withdraw
                        </CardTitle>

                        <Accordion defaultActiveKey="0">
                          <Accordion.Item className="Withdraw-acc" eventKey="0">
                            <Accordion.Header className="acc-header">
                              <h6>
                                {" "}
                                <span>1</span> Available balance
                              </h6>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              <div className="withdraw-body">
                                <div className="help-link">
                                  <Link>Need help? See FAQ</Link>
                                </div>
                                <div className="warning-line">
                                  <img src={Warning} />
                                  This account does not have any USD available
                                  to withdraw.
                                </div>
                                <div className="case-body-inner">
                                  <div className="">
                                    <h6>Case balance</h6>
                                    <h6>Pending transfers</h6>
                                  </div>
                                  <div className="text-end">
                                    <h6>$0.00 USD</h6>
                                    <h6>-$19.00 USD</h6>
                                  </div>
                                </div>
                                <div className="al-amount">
                                  <h6>Available balance</h6>
                                  <h6>$0.00 USD</h6>
                                </div>
                                <div className="limit-action">
                                  <img src={LimitAction} />
                                  <div className="withdraw-outer">
                                    <h6>Withdraw limit</h6>
                                    <span>Your withdraw limit is exceed.</span>
                                  </div>
                                </div>
                                <Button className="mb-3">Continue</Button>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item className="Withdraw-acc" eventKey="1">
                            <Accordion.Header className="acc-header">
                              <h6>
                                <span>2</span> Select destination
                              </h6>
                            </Accordion.Header>
                            <Accordion.Body></Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item className="Withdraw-acc" eventKey="2">
                            <Accordion.Header className="acc-header">
                              <h6>
                                <span>3</span> Select destination
                              </h6>
                            </Accordion.Header>
                            <Accordion.Body></Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item className="Withdraw-acc" eventKey="3">
                            <Accordion.Header className="acc-header">
                              <h6>
                                <span>4</span> Select destination
                              </h6>
                            </Accordion.Header>
                            <Accordion.Body></Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                      <Card className="mt-3">
                        <CardTitle className="">Transfer history</CardTitle>
                        <div className="transfer-list">
                          <div className="colum-01">
                            <h6>Deposit</h6>
                            <p>
                              24 hours ago{" "}
                              <span className="dots-active">Complete</span>
                            </p>
                          </div>
                          <div className="colum-02">$5 USD</div>
                          <div className="colum-03">
                            <span>
                              <img src={Dotted} />
                            </span>
                          </div>
                        </div>
                        <div className="transfer-list">
                          <div className="colum-01">
                            <h6>Deposit</h6>
                            <p>
                              24 hours ago{" "}
                              <span className="dots-active">Complete</span>
                            </p>
                          </div>
                          <div className="colum-02">$5 USD</div>
                          <div className="colum-03">
                            <span>
                              <img src={Dotted} />
                            </span>
                          </div>
                        </div>
                        <div className="transfer-list">
                          <div className="colum-01">
                            <h6>Deposit</h6>
                            <p>
                              24 hours ago{" "}
                              <span className="dots-active">Complete</span>
                            </p>
                          </div>
                          <div className="colum-02">$5 USD</div>
                          <div className="colum-03">
                            <span>
                              <img src={Dotted} />
                            </span>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col sm={24} md={8}>
                      <Card className="withdraw-card">
                        <CardTitle className="account-tital">
                          Asset balances
                        </CardTitle>
                        <div className="asset-menu">
                          <div className="case-asset">
                            <div className="">
                              <h6>Case balance</h6>
                            </div>
                            <div className="">
                              <h6>$0.00 USD</h6>
                            </div>
                          </div>
                          <div className="case-asset-list">
                            <div className="">
                              <h6>Gold</h6>
                            </div>
                            <div className="asset-right">
                              <p>$6.78 USD</p>
                              <span>0.567 OZ</span>
                            </div>
                          </div>
                          <div className="case-asset-list">
                            <div className="">
                              <h6>Silver</h6>
                            </div>
                            <div className="asset-right">
                              <p>$6.78 USD</p>
                              <span>0.567 OZ</span>
                            </div>
                          </div>
                          <div className="case-asset-list">
                            <div className="">
                              <h6>Palladium</h6>
                            </div>
                            <div className="asset-right">
                              <p>$6.78 USD</p>
                              <span>0.567 OZ</span>
                            </div>
                          </div>
                          <div className="case-asset-list">
                            <div className="">
                              <h6>Platinum</h6>
                            </div>
                            <div className="asset-right">
                              <p>$6.78 USD</p>
                              <span>0.567 OZ</span>
                            </div>
                          </div>
                        </div>
                      </Card>
                      <Card className="withdraw-card mt-3">
                        <CardTitle className="account-tital">
                          USD withdrawal holds
                        </CardTitle>
                        <div className="asset-menu last-resulting">
                          <span>
                            You have multiple pre-credited USD transfer,
                            resulting in a hold of $19.32 USD on your USD
                            balance.
                          </span>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default Indextransfer;
