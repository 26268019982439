import { Container } from "react-bootstrap";
import payments04 from "../../assets/images/Possible-payments 03.svg";
import { Layout, Row, Col, Input, Button, Form } from "antd";
import { HeartFilled } from "@ant-design/icons";
import payments01 from "../../assets/images/Possiblepayments01.svg";
import payments02 from "../../assets/images/Possible-payments.svg";
import payments03 from "../../assets/images/Possible-payments 02.svg";
import Playstore from "../../assets/images/play-app-store.svg";
import iphone from "../../assets/images/iphone-app-store.svg";
import HomeIcon from "../../assets/images/home-icon.svg";
import PortfolioIcon from "../../assets/images/portfolio-icon.svg";
import SettingIcon from "../../assets/images/setting-icon.svg";

import { useAuthContext } from "../../context/AuthContext";

import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  NavLink,
} from "react-router-dom";
import { useState } from "react";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import { useForm } from "antd/lib/form/Form";
import lang from "../../helper/langHelper";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, userProfile } = useAuthContext();
  const [email, setEmail] = useState("");
  const { request } = useRequest();
  const [deleting, setDeleing] = useState(false);
  const [form] = Form.useForm();

  const handleSubscribe = (values) => {
    setDeleing(true);
    const payload = {};
    payload.email = email;
    request({
      url: `/common/subscribe`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        console.log(data, "faq");
        ShowToast(data.message, Severty.SUCCESS);
        setDeleing(false);
        setEmail("");
        form.resetFields();
      },
      onError: (err) => {
        console.log(err, "faq");
        ShowToast(err?.response?.data?.message, Severty.ERROR);
        setDeleing(false);
      },
    });
  };

  return (
    <>
      <AntFooter className="footer d-none d-md-block">
        <Container>
          <div className="footer-first">
            <Row gutter={12}>
              <Col span={24} md={12} lg={4}>
                <div className="footer-headding">
                  <h6>{lang("Company")}</h6>
                  <ul className="footer-subline">
                    <li>
                      {" "}
                      <Link to="/About">{lang("About mynt")}</Link>
                    </li>
                    {/* <li><Link to=''>Reviews</Link></li> */}
                  </ul>
                </div>
              </Col>

              <Col span={24} md={12} lg={5}>
                <div className="footer-headding">
                  <h6>{lang("Learn")}</h6>
                  <ul className="footer-subline">
                    <li>
                      {" "}
                      <Link to="/how-it-works">{lang("How it Works")}</Link>
                    </li>
                    {/* <li> <Link to='/pricing'>Pricing </Link></li> */}
                    {/* {isLoggedIn && <li> <Link to='/AutoInvest'>Auto Invest </Link></li>}
                    {isLoggedIn && <li> <Link to='/CashBalance'>Deposits/Withdrawals</Link></li>}
                    {isLoggedIn && <li> <Link to=''>Physical Redemptions</Link></li>}
                    {isLoggedIn && <li> <Link to=''>Storage</Link></li>} */}
                  </ul>
                </div>
              </Col>
              <Col span={24} md={12} lg={4}>
                <div className="footer-headding">
                  <h6>{lang("Support")}</h6>
                  <ul className="footer-subline">
                    <li>
                      {" "}
                      <Link to="/contact">{lang("Contact Us")}</Link>
                    </li>
                    <li>
                      <Link to="/Faq">{lang("FAQ")}</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={24} md={12} lg={6}>
                <div className="footer-subscribe">
                  <h6>{lang("Subscribe")}</h6>
                  <div className="footer-subscribe-text">
                    <p>
                      {lang("Subscribe to the mynt newsletter to receive timely market updates as well as info on sales and giveaways")}.
                    </p>
                  </div>
                  <div className="subscribe-box">
                    <Form
                      onFinish={handleSubscribe}
                      form={form}
                      style={{ display: "flex" }}
                    >
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: lang("The input is not a valid email!"),
                          },
                          {
                            required: true,
                            message: lang("Please input your email!"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={lang("Enter your email")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          loading={deleting}
                          className="subscribe-btn"
                          type="primary"
                          htmlType="submit"
                        >
                          {lang("Subscribe")}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer-sec">
            <Row gutter={24}>
              <Col span={24} lg={12}>
                <div className="footer-headding-second">
                  <h6>{lang("Major Payment Method Accepted")}</h6>
                  <div className="footer-subline-img">
                    <Link>
                      <img src={payments01} />
                    </Link>
                    <Link>
                      <img src={payments02} />
                    </Link>
                    <Link>
                      <img src={payments04} />
                    </Link>
                    <Link>
                      {" "}
                      <img src={payments03} />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col span={24} lg={12}>
                <div className="footer-headding-sp">
                  <h6>{lang("Download Our Free App")}</h6>
                  <div className="footer-second-img">
                    <Link
                      onClick={() =>
                        window.open("https://www.apple.com/in/app-store/")
                      }
                    >
                      <img src={iphone} />
                    </Link>
                    <Link
                      onClick={() =>
                        window.open("https://play.google.com/store/games")
                      }
                    >
                      <img src={Playstore} />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer-three">
            <Row gutter={12}>
              <Col md={24}>
                <div className="footer-bottam">
                  <ul>
                    <li>
                      <Link>© 2024 mynt</Link>
                    </li>
                    <li>
                      <Link to="/privacypolicy">{lang("Privacy policy")}</Link>
                    </li>
                    <li>
                      <Link to="/cookie-policy">{lang("Cookie Policy")}</Link>
                    </li>
                    <li>
                      <Link to="/terms">{lang("Terms & Conditions")}</Link>
                    </li>
                    <li>
                      <Link to="/accessibility">{lang("Accessibility")}</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </AntFooter>
      {/* <AntFooter className="mobile-footer d-md-none">
       {
        userProfile ?  
        <div className="mobile-nevigation">
        <Link
          as={NavLink}
          to="/"
          className={`nevigation-icon ${
            location.pathname === "/" ? " active" : ""
          }`}
        >
          <img src={HomeIcon} />
          <h6>Home</h6>
        </Link>
        <Link
          as={NavLink}
          to="/portfolio"
          className={`nevigation-icon ${
            location.pathname === "/portfolio" ? "active" : ""
          }`}
        >
          <img src={PortfolioIcon} />
          <h6>Portfolio</h6>
        </Link>
        <Link
          as={NavLink}
          to="/account"
          className={`nevigation-icon ${
            location.pathname === "/account" ? "active" : ""
          }`}
        >
          <img src={SettingIcon} />
          <h6>Account</h6>
        </Link>
      </div> : null
       }
      </AntFooter> */}
    </>
  );
}

export default Footer;
