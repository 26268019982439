import { Col } from "antd";
import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import User01 from "../../assets/images/side_nav/user.svg";
import Wallet from "../../assets/images/side_nav/wallet.svg";
import Timeuser from "../../assets/images/side_nav/time.svg";
import Notification from "../../assets/images/side_nav/basil_notification.svg";
import UserPlus from "../../assets/images/side_nav/user-plus.svg";
import Logout from "../../assets/images/side_nav/logout-outlined.svg";
import Securityimg from "../../assets/images/side_nav/securitymargin.svg";
import Paymentmethod from "../../assets/images/side_nav/paymentmethod.svg";
import Verification from "../../assets/images/side_nav/verification.svg";
import Autobuy from "../../assets/images/side_nav/autobuy.svg";
import Invoice from "../../assets/images/side_nav/invoice-light.svg";
import UserIcon from "../../assets/images/side_nav/user.svg";
import Filter from "../../assets/images/filter.png";
import Menu from "../../assets/images/menu.png";
import RightIcon from "../../assets/images/right-errow.png";
import { AuthContext } from "../../context/AuthContext";
import ConfirmLogoutModal from "../ConfirmLogoutModal";
import lang from "../../helper/langHelper";

const AccountSideNav = ({}) => {
  const location = useLocation();

  const [show, setShow] = useState(false);
  const { logout } = useContext(AuthContext);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const navigate = useNavigate();

  const showDeleteConfirm = (record) => {
    navigate("/login");
    logout();
  };

  return (
    <>
      <Col span={24} md={8} lg={6} xxl={4} className="d-none d-md-block ">
        <div className="side-nav-main">
          <div className="fillter-sidebar">
            <Link className="sidebar-btn" onClick={() => setShow(!show)}>
              <img className=" " width={25} src={Menu} />
            </Link>
          </div>
          <div className={show ? "sideshow" : "sidehide"}>
            <div className="side-nav">
              <ul>
                <li
                  className={`nav-items ${
                    location.pathname === "/account" ? "active" : ""
                  }`}
                >
                  <Link to="/account">
                    <img src={User01} />
                    {lang("Account Info")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/security" ? "active" : ""
                  }`}
                >
                  <Link to="/security">
                    <img src={Securityimg} />
                    {lang("Security")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/portfolio" ? "active" : ""
                  }`}
                >
                  <Link to="/portfolio">
                    <img src={Securityimg} />
                    {lang("Portfolio")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/PeymentMethod" ? "active" : ""
                  }`}
                >
                  <Link to="/PeymentMethod">
                    <img src={Paymentmethod} />
                    {lang("Payment Methods")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/CashBalance" ||
                    location.pathname === "/CashBalance/Deposit"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link to="/CashBalance">
                    <img src={Wallet} />
                    {lang("Cash Balance")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/transactions" ? "active" : ""
                  }`}
                >
                  <Link to="/transactions">
                    <img src={Timeuser} />
                    {lang("Transactions")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/AutoInvest" ||
                    location.pathname === "/AutoInvest/create"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link to="/AutoInvest">
                    <img src={Autobuy} />
                    {lang("Auto Invest")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/Notifications" ? "active" : ""
                  }`}
                >
                  <Link to="/Notifications">
                    <img src={Notification} />
                    {lang("Notifications")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/InviteFriends" ? "active" : ""
                  }`}
                >
                  <Link to="/InviteFriends">
                    <img src={UserPlus} />
                    {lang("Invite Friends")}
                  </Link>
                </li>
                <li
                  className={`nav-items${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link
                    onClick={() => setIsLogoutModalVisible(true)}
                  >
                    <img src={Logout} />
                    {lang("Logout")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} md={8} lg={6} xxl={4} className="d-block d-md-none ">
        <div className="side-nav-main">
          <div className={show ? "sideshow" : "sidehide"}>
            <div className="side-nav">
              <ul>
                <li
                  onClick={() => navigate("/account")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/account" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UserIcon} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/account">{lang("Account Info")}</Link>
                      <p>{lang("Edit name and view address details")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/security")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/security" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={Securityimg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/security">{lang("Security")}</Link>
                      <p>{lang("Set up Passcode to access")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/portfolio")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/portfolio" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={Securityimg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/portfolio">{lang("Portfolio")}</Link>
                      <p>{lang("See portfolio")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/PeymentMethod")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/PeymentMethod" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={Paymentmethod} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/PeymentMethod">{lang("Payment Methods")}</Link>
                      <p>{lang("View and manage your payment methods")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/CashBalance")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/CashBalance" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={Wallet} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/CashBalance">{lang("Cash Balance")}</Link>
                      <p>{lang("View and manage your balance")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/transactions")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/transactions" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={Timeuser} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/transactions">{lang("Transactions")}</Link>
                      <p>{lang("View your transaction history")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/AutoInvest")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/AutoInvest" ||
                    location.pathname === "/AutoInvest/create"
                      ? "active"
                      : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={Autobuy} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/AutoInvest">{lang("Auto Invest")}</Link>
                      <p>{lang("View your Invest")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/Notifications")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/Notifications" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={Notification} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/Notifications">{lang("Notifications")}</Link>
                      <p>{lang("View and manage your notifications")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/InviteFriends")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/InviteFriends" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UserPlus} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/InviteFriends">{lang("Invite Friends")}</Link>
                      <p>{lang("Invite your friends")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  className={`nav-items mobile-side ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link onClick={() => setIsLogoutModalVisible(true)}>
                    <img src={Logout} />
                    {lang("Logout")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>
      {isLogoutModalVisible && (
        <ConfirmLogoutModal
          reasons={[]}
          title={lang("Logout")}
          subtitle={lang("Are you sure you want to logout ?")}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => showDeleteConfirm()}
        />
      )}
    </>
  );
};

export default AccountSideNav;
