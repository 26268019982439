import { } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
} from "antd";
import 'react-phone-input-2/lib/style.css';
import { } from "../../helper/toast";

import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";

import { useNavigate } from "react-router-dom";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AccountSideNav from "../../components/layout/AccountSideNav";
import Header from "../../components/layout/Header";
import Footer from '../../components/layout/Footer';

function Verificationindex() {
  const { request } = useRequest();


  const navigate = useNavigate()
  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [show, setShow] = useState(false);






  return (
    <>
      <section className="main">
        <Header />
        <div className="account-outers">
          <Row>
            <AccountSideNav />
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="transactions-header">
                  <div className="table-headding">
                    Verification
                  </div>
                </div>
                <Card>
                  <div className="verification-outer">
                    <h6>Verification Status: </h6>
                    <Button className="danger-btn">Unverified</Button>
                  </div>
                  <div className="verification-body">
                    <p>Your account is not yet verified. You must complete the account verification process before withdrawing funds or requesting delivery of physical precious metals.</p>
                    <Button>Verify </Button>
                  </div>
                </Card>
              </div>
            </Col>

          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}



export default Verificationindex;
